@import '~antd/dist/antd.css';
.logo {
  font-size: 18px;
  font-weight: bold;
  margin-right: 24px;
}
.ant-layout,
.ant-layout-header {
  background: #FFFFFF;
}
.ant-layout-header {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.03), 0 1px 6px -1px rgba(0, 0, 0, 0.02), 0 2px 4px 0 rgba(0, 0, 0, 0.02);
}
.ant-layout-sider {
  background: #fafafa;
}
.AddSellForm .ant-form-item-explain,
.AddSellForm .ant-form-item-explain-error {
  display: none !important;
}
#root {
  height: 100vh;
}

::-webkit-scrollbar-track
{
	background-color: #F5F5F5;
}

::-webkit-scrollbar
{
	width: 6px;
}

::-webkit-scrollbar-thumb
{
	background-color: rgba(0, 0, 0, 0.1);
}

b {
  font-weight: 500;
  color: #555;
}